import React from "react";
import { graphql, useStaticQuery} from "gatsby";

import {
  Box
} from "@chakra-ui/react";

import Post from "./Post";

const DISPLAY_FEATURE = true;  // set to true for false. No quotes


// TODO - use snippets to share field list and image params
export default function FeaturePost(props) {
  const data = useStaticQuery(
    graphql`
      {
        allMdx(
          filter: {slug: {eq: "blog/2024-11-12-dreaming-of-a-slave-free-christmas"}}
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                description
                date(formatString: "MMMM DD, YYYY")
                image {
                  childImageSharp {
                    gatsbyImageData(width: 672, height: 336)
                  }
                }
              }
              slug
            }
          }
        }
      }
    `
  );
  const node = data.allMdx.edges[0].node;
  node.frontmatter.description = "Slave free options for chocolate gifts and festive food.";
  node.frontmatter.title = "Dreaming of a slave free Christmas?"
    
  return (
    <Box as="aside" id="featurePost" {...props} display={DISPLAY_FEATURE? "" : "none"}>
      <Post node={node} showTags={false} />
    </Box>
  )
}
